import { useEffect, useRef } from "react";
import { IntlProvider } from "react-intl";
import { ClientOnly } from "remix-utils/client-only";

import type { ShouldRevalidateFunction } from "@remix-run/react";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useSearchParams,
} from "@remix-run/react";
import type { LinksFunction } from "@remix-run/server-runtime";
import { json } from "@remix-run/server-runtime";

import { getContext } from "./commerce-sap/.server/context.server";
import { getSessionServerContext } from "./commerce-sap/.server/sessions.server";
import { fetchTranslationsFromKv } from "./lib/utils-server/locale.server";

import { createUrlTemplate, getDomainUrl } from "@commerce/lib/url";
import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";

import { env as _env } from "~/lib/env";

import { SiteProvider } from "./contexts";
import { GTMProvider } from "./google-tagmanager";
import { Insider } from "./insider";
import { DEFAULT_COUNTRY_CODES } from "./lib/constants";
import { getCountryByCode, getCountryByLocale } from "./lib/countries";
import { ClientHintCheck, getHints } from "./lib/utils/client-hints";
import { useLoadScript } from "./lib/utils/load-script";
import { DeferSeo } from "./seo/seo";
import styles from "./styles/app.css?url";
import "./styles/radix-themes/colors-light.css";
import "./styles/radix-themes/radius.css";
import "./styles/radix-themes/scaling.css";
import "./styles/radix-themes/storefront.css";
import "./styles/radix-themes/theme.css";
import "./styles/swiper.css";

export { RootErrorBoundary as ErrorBoundary } from "~/components/error-boundries/root-error-boundary";

export const loader = async ({ request, context }: LoaderArgs) => {
  const { locale, currency, site, lang } = context;
  const translations = await fetchTranslationsFromKv(lang);
  const preferredCountryCode = context.session.get("preferredCountryCode");
  const { env } = getSessionServerContext();

  const {
    BV_CLIENT_NAME,
    BV_ENVIRONMENT,
    BV_LOCALE,
    BV_SITE_ID,
    GOOGLE_SITE_VERIFICATION,
  } = getSessionServerContext().env;
  const BV_JS_URL = `https://apps.bazaarvoice.com/deployments/${BV_CLIENT_NAME}/${BV_SITE_ID}/${BV_ENVIRONMENT}/${BV_LOCALE}/bv.js`;

  let preferredCountry =
    preferredCountryCode && getCountryByCode(preferredCountryCode);
  if ((preferredCountry && preferredCountry.locale) !== locale.id) {
    preferredCountry = getCountryByLocale(locale.id);
  }

  const requestCountryCode = getContext().request.cf?.isEUCountry
    ? DEFAULT_COUNTRY_CODES.GB
    : DEFAULT_COUNTRY_CODES.US;
  //TODO: Redirect if request is not on the preferred site
  // const [preferredLocale, Site] = (preferredCountryCode &&
  //   getLocaleByCountry(preferredCountryCode)) || [undefined, undefined];
  return json({
    locale,
    currency,
    site,
    lang,
    translations,
    APP_DEFAULT_LANG,
    BV_JS_URL,
    GOOGLE_SITE_VERIFICATION,
    googleMapsApiKey: _env(env.GOOGLE_MAPS_API_KEY, "GOOGLE_MAPS_API_KEY"),
    requestInfo: {
      reCaptchaSiteKey: env.RECAPTCHA_SECRET_KEY,
      ismobile: context.ismobile,
      requestCountryCode,
      isEUCountry: getContext().request.cf?.isEUCountry,
      hints: getHints(request),
      origin: getDomainUrl(request),
      /** *preferred country from session* or request country if not set */
      preferredCountry:
        preferredCountry || getCountryByCode(requestCountryCode),
      /** actual request country */
      country: requestCountryCode
        ? getCountryByCode(requestCountryCode)
        : undefined,
    },
  });
};

export const shouldRevalidate: ShouldRevalidateFunction = ({ formData }) => {
  if (["setCountry"].includes(formData?.get("action")?.toString() || "")) {
    return true;
  }
  return false;
};

export const links: LinksFunction = () => {
  return [{ rel: "stylesheet", href: styles }];
};

export default function App() {
  // Get the locale from the loader
  const { lang, BV_JS_URL, GOOGLE_SITE_VERIFICATION, googleMapsApiKey } =
    useLoaderData<typeof loader>();
  const [searchParams] = useSearchParams();
  const preview = !!searchParams.get("preview");

  return (
    <ContentfulLivePreviewProvider
      // currently our contentful settings support only "en-AU" translation, so in order for the preview functionality to work, we need to pass it as a value for the locale below
      locale={"en-AU"}
      enableInspectorMode={preview}
      enableLiveUpdates={preview}
    >
      <html lang={lang} className="h-full">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <link rel="preconnect" href="https://images.ctfassets.net/" />
          <script
            async
            defer
            src={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places,geometry`}
          ></script>
          <link
            rel="preload"
            href="/fonts/nunito-sans-normal.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/fonts/nunito-sans-italic.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <DeferSeo />
          <Meta />
          <ClientHintCheck nonce={""} />
          {/* All meta exports on all routes will go here */}
          {/* All link exports on all routes will go here */}
          <Links />
          <meta
            name="google-site-verification"
            content={GOOGLE_SITE_VERIFICATION}
          />
        </head>
        <body className="rendered sm-max:pt-24 relative min-h-full bg-brand-background text-base tracking-normal [&_iframe]:pointer-events-auto">
          <div
            id="backdrop-quick-search"
            className="absolute top-0 z-50 hidden h-[100%] w-[100%] bg-black bg-opacity-40"
          ></div>
          <OnLocationChange />
          <InjectScript url={BV_JS_URL} />
          <AppRoot>
            <Outlet />
          </AppRoot>

          {/* Manages scroll position for client-side transitions */}
          {/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
          <ScrollRestoration />
          {/* Script tags go here */}
          {/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
          <Scripts />
          {/* Sets up automatic reload when you change code */}
          {/* and only does anything during development */}
          {/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
        </body>
      </html>
    </ContentfulLivePreviewProvider>
  );
}

export function AppRoot({ children }: { children: React.ReactNode }) {
  const { lang, site, locale, translations, APP_DEFAULT_LANG } =
    useLoaderData<typeof loader>();
  const buildUrl = createUrlTemplate(locale);
  return (
    <>
      <SiteProvider buildUrl={buildUrl} locale={locale} site={site}>
        <IntlProvider
          onError={err => {
            if (err.code === "MISSING_TRANSLATION") {
              /* NOTE: Remove the console error for missing translations during development, */
              /* as we knew translations would be added later. */
              // console.warn("Missing translation", err.message);
              return;
            }
            console.error(err);
            // throw err;
          }}
          locale={lang}
          // @ts-expect-error - serialize the translations causese a type error
          messages={translations}
          // For react-intl, the _default locale_ refers to the locale that the inline `defaultMessage`s are written for.
          // NOTE: if you update this value, please also update the following npm scripts in `package.json`:
          // - "extract-default-translations"
          // - "compile-translations:pseudo"
          defaultLocale={APP_DEFAULT_LANG}
        >
          <GTMProvider>{children}</GTMProvider>

          {/* Insider */}
          <ClientOnly>{() => <Insider />}</ClientOnly>
        </IntlProvider>
      </SiteProvider>
    </>
  );
}

/**
 * This component is used to remove the no-transition class from the body when the location changes.
 * enabled css transitions
 */
const OnLocationChange = () => {
  const location = useLocation();
  const ref = useRef<ReturnType<typeof useLocation> | null>(location);
  useEffect(() => {
    // document.body.classList.add(noTransitionStyles["enable-transitions"]);
    ref.current = null;
  }, []);
  return null;
};

const InjectScript = ({ url }: { url: string }) => {
  useLoadScript(url);
  return null;
};
