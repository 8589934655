/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Links,
  Meta,
  Scripts,
  isRouteErrorResponse,
  useRouteError,
} from "@remix-run/react";

const is404 = (error: unknown) => {
  if (!isRouteErrorResponse(error)) return false;
  return error.status === 404;
};

export const RootErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);
  const homeUrl = `/`;
  return (
    <html lang="en">
      <head>
        <title>Error</title>
        <Meta />
        <Links />
      </head>
      <body className=" work-sans  h-screen min-h-full bg-white text-base tracking-normal text-gray-600">
        {/* Error page UI */}
        {is404(error) && (
          <main className="relative isolate min-h-full">
            <img
              src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
              alt="Error page"
              className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
            />
            <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
              <p className="text-base font-semibold text-white">404</p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
                Page not found
              </h1>
              <p className="mt-4 text-base text-white/70 sm:mt-6">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-10 flex justify-center">
                <a href={homeUrl} className="text-sm font-semibold text-white">
                  <span aria-hidden="true">&larr;</span> Back to home
                </a>
              </div>
            </div>
          </main>
        )}
        {!is404(error) && isRouteErrorResponse(error) && (
          <section className="flex h-full w-full items-center dark:bg-gray-900 dark:text-gray-100 sm:p-16 ">
            <div className="container mx-auto my-8 flex flex-col  items-center justify-center space-y-8 px-5 py-10 text-center sm:max-w-md md:max-w-7xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-40 w-40 dark:text-gray-600"
              >
                <path
                  fill="currentColor"
                  d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"
                ></path>
                <rect
                  width="176"
                  height="32"
                  x="168"
                  y="320"
                  fill="currentColor"
                ></rect>
                <polygon
                  fill="currentColor"
                  points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"
                ></polygon>
                <polygon
                  fill="currentColor"
                  points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"
                ></polygon>
              </svg>
              <p className="text-3xl">
                Looks like our services are currently offline
              </p>
              {!!error && (
                <div className="w-1/2 overflow-scroll  border p-10">
                  <p className=" text-left ">
                    <pre>
                      {(error as any)?.error?.stack ||
                        (error as any).error?.message}
                    </pre>
                  </p>
                </div>
              )}

              <a
                rel="noopener noreferrer"
                href={homeUrl}
                className="rounded px-8 py-3 font-semibold dark:bg-violet-400 dark:text-gray-900"
              >
                Back to homepage
              </a>
            </div>
          </section>
        )}
        {!isRouteErrorResponse(error) && (
          <section className="flex h-full w-full items-center dark:bg-gray-900 dark:text-gray-100 sm:p-16 ">
            <div className="container mx-auto my-8 flex flex-col  items-center justify-center space-y-8 px-5 py-10 text-center sm:max-w-md md:max-w-7xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-40 w-40 dark:text-gray-600"
              >
                <path
                  fill="currentColor"
                  d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"
                ></path>
                <rect
                  width="176"
                  height="32"
                  x="168"
                  y="320"
                  fill="currentColor"
                ></rect>
                <polygon
                  fill="currentColor"
                  points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"
                ></polygon>
                <polygon
                  fill="currentColor"
                  points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"
                ></polygon>
              </svg>
              <p className="text-3xl">
                Looks like our services are currently offline
              </p>
              {!!error && error instanceof Error && (
                <div className="w-1/2 overflow-scroll  border p-10">
                  <p className=" text-left ">
                    <pre
                      dangerouslySetInnerHTML={{
                        __html: addLinksToStackTrace(
                          error.stack || error.message,
                        ),
                      }}
                    />
                  </p>
                </div>
              )}

              <a
                rel="noopener noreferrer"
                href={homeUrl}
                className="rounded px-8 py-3 font-semibold dark:bg-violet-400 dark:text-gray-900"
              >
                Back to homepage
              </a>
            </div>
          </section>
        )}
        <Scripts />
      </body>
    </html>
  );
};

function addLinksToStackTrace(stackTrace: string) {
  return stackTrace?.replace(/^\s*at\s?.*?[(\s]((\/|\w).+)\)\n/gim, (all, m1) =>
    all.replace(
      m1,
      `<a href="vscode://file${m1}" class="hover:underline">${m1}</a>`,
    ),
  );
}
